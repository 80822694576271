<template>
    <div class="smsCenter_wpr">
        <div class="msg_board">
            <div class="user_header">
                <h3>
                    <img :src="contact.photo" alt="" class="mr-3 user_img">{{ contact.name }}
                </h3>
                <ul class="chatbox-header">
                    <li @click="scheduledMessage = true" v-tooltip="`Scheduled Message`" position="left">
                        <img src="@/assets/images/calendar-alt.svg">
                    </li>
                    <li @click="lauchPad = true" v-tooltip="`Launchpad`" position="left">
                        <img src="@/assets/images/launchpad.svg">
                    </li>
                </ul>
            </div>
            <portal-chat-box :contact="contact" :is-contact="true" ref="contact-portal-chatbox" />
        </div>
    </div>
    <launch-pad v-model="lauchPad" :contact="contact" />
    <scheduled-message v-model="scheduledMessage" :contact-id="contact.id" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const PortalChatBox = defineAsyncComponent(() => import('@/pages/sms-center/portal-chat/PortalChatBox'))
    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ScheduledMessage = defineAsyncComponent(() => import('@/pages/sms-center/portal-chat/ScheduledMessage'))

    import Pusher from 'pusher-js'

    export default {
        name: 'Portal Chat Dashboard',

        data () {
            return {
                scheduledMessage: false,
                lauchPad: false,
            };
        },

        props: {
            contact: Object,
        },

        components: {
            PortalChatBox,
            LaunchPad,
            ScheduledMessage,
        },

        mounted () {
            const vm = this;

            const chatBoxCheck = setInterval(function () {
                const chatBox = vm.$refs['contact-portal-chatbox'];

                if (chatBox) {
                    clearInterval(chatBoxCheck)
                    chatBox.resetForm();
                }
            }, 10);
        }
    }
</script>

<style scoped>
    .smsCenter_wpr {
        display: flex;
        background: #fff;
    }

    .msg_board {
        flex: 1;
        margin-left: 30px;
    }

    .msg_board .user_header {
        padding: 15px 30px;
        background: #f8f8f8;
        border-radius: 10px 10px 0 0;
        /* border: 1px solid #eaeaea; */
        border-bottom: 0;
        display: flex;
        align-items: center;
    }

    .msg_board .user_header h3 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .msg_board .user_header ul {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .msg_board .user_header ul li {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #E0E5EB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        cursor: pointer;
    }

    .msg_board .user_header ul li img {
        filter: grayscale(1);
        width: 15px;
        height: auto;
    }

    .msg_board .chat {
        height: calc(100vh - 200px);
    }

    .smsCenter_wpr .user_list {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 0 10px 10px 0;
        height: 100%;
        flex: 0 0 300px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .smsCenter_wpr .user_list::-webkit-scrollbar {
        display: none;
    }

    .smsCenter_wpr .user_list .search {
        padding: 20px 15px;
        border-bottom: 1px solid #eee;
        position: sticky;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 1;
    }

    .msg_board .user_header .mr-3.user_img {
        max-width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .chatbox-header li {
        position: relative;
    }

    .chatbox-header li .tooltip {
        position: absolute;
        right: 0;
        top: 100%;
        padding: 4px 8px;
        border-radius: 3px;
        font-size: 11px;
        line-height: 13px;
        color: #fff;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        background: #32373b;
    }

    .chatbox-header li .tooltip:before {
        position: absolute;
        content: '';
        right: 20px;
        top: -3px;
        width: 6px;
        height: 6px;
        background: #32373b;
        transform: rotate(-45deg);
    }

    .chatbox-header li:hover .tooltip {
        opacity: 1;
    }
    .toggle_bar{
        width: 20px;
        height: 20px;
        font-size: 12px;
        border: 1px solid #dbdbdb;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 8px 0 auto;
        cursor: pointer;
        color: #5a5a5a;
    }
    .chat_listing{
        margin: 0 0 30px 0;
        padding: 15px 20px;
        width: 100%;
        border: 1px solid #eee;
        background: #fff;
        border-radius: 10px;
    }
    .chat_listing :deep(.swiper-wrapper){
        gap: 10px;
    }
    .chat_listing .swiper-slide{
        width: auto;
    }
    .chat_listing .chat_tab{
        font-size: 13px;
        line-height: 18px;
        padding: 8px 20px;
        border-radius: 20px;
        border: 1px solid #eee;
        background: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
    }
    .chat_listing .chat_tab img{
        max-height: 18px;
        height: auto;
        margin-right: 7px;
    }
    .chat_listing .chat_tab span{
        position: absolute;
        top: -8px;
        right: 0;
        background: #F2A31D;
        width: 18px;
        height: 18px;
        font-size: 10px;
        line-height: 16px;
        color: #fff;
        border-radius: 9px;
        text-align: center;
    }
    .chat_listing .chat_tab.active{
        background: #2f7eed;
        color: #fff;
    }
    .chat_listing :deep(.swiper-button-next.swiper-button-disabled), .chat_listing :deep(.swiper-button-prev.swiper-button-disabled){
        opacity: 0;
    }
    .chat_listing :deep(.swiper-button-next:after), .chat_listing :deep(.swiper-button-prev:after){
        font-size: 13px;
        color: #5a5a5a;
        font-weight: 600;
    }
    .chat_listing :deep(.swiper-button-prev), .chat_listing :deep(.swiper-container-rtl .swiper-button-next){
        left: 0;
        width: 20px;
        background: #fff;
        cursor: pointer;
        top: 0;
        height: 100%;
        margin: 0;
    }
    .chat_listing :deep(.swiper-button-next), .chat_listing :deep(.swiper-container-rtl .swiper-button-prev){
        right: 0;
        width: 20px;
        background: #fff;
        cursor: pointer;
        top: 0;
        height: 100%;
        margin: 0;
    }
    .no-portal-chat{
        height: calc(100vh - 315px);
        background: #fff;
        border: 1px solid #eee;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .no-portal-chat h2{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }
    @media (max-width: 1499px){
        .respective_content {
            padding-left: 10px;
        }
    }
    @media(min-width: 1500px){
        .respective_content{
            padding-left: 30px;
        }
    }

    h2.no_chat{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
</style>
